import { Toast } from '@coreui/coreui-pro';

const toastElementTemple = document.getElementById('toast-template');
const container = document.querySelector('.toast-container');

const TOAST_DELAY = 4500;

export function showMessage(tag, message) {
  let node = toastElementTemple.cloneNode(true);
  node.classList.add(`text-bg-${tag ?? 'secondary'}`);

  const toast = new Toast(node, { delay: TOAST_DELAY });
  const toastBody = node.querySelector('#toast-body');
  toastBody.innerHTML = message;
  container.appendChild(node);
  toast.show();
}

(() => {
  'use strict';
  function showMessages(e) {
    if (!e.detail.messages) {
      return;
    }

    e.detail.messages.forEach((m) => {
      const [tag, message, extra_tags] = m;
      showMessage(tag, message);
    });
  }

  function initialMessages() {
    container.querySelectorAll('.toast').forEach((node) => {
      const toast = new Toast(node, { delay: TOAST_DELAY });
      toast.show();
    });
  }

  window.addEventListener('DOMContentLoaded', () => {
    initialMessages();

    htmx.on('showMessages', (e) => {

      showMessages(e);
    });
  });
})();
